$lightOrange: #ffa726;
$darkOrange: #fb8c00;

$darkBlue: #4dd7e6;
$lightBlue: rgb(136, 243, 255);

$deepBlue: #4d94e6;
$deepBlueLight: #6d99cc;

body {
  font-family: "Open Sans", sans-serif;
  color: black;
  margin: 0;
  padding: 0;
}

.body,
.foot,
.btn {
  transition: background-color 500ms linear;
}

.body {
  height: 100%;
  width: 100%;
  position: fixed;

  background-color: $lightBlue;

  &.dog-theme {
    background-color: $lightOrange;

    .btn,
    .btn:focus {
      background-color: $deepBlue;
    }

    .btn:hover {
      background-color: $deepBlueLight;
    }

    .foot {
      background-color: $darkOrange;
    }
  }
}

.title {
  font-family: "Bitter", serif;
  text-align: center;
  font-size: 2.4em;
}

.container {
  margin-top: 40px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  max-width: 640px;
  box-shadow: 3px 3px 4px 1px #000000;
}

.fact-box {
  height: 220px;
}

.btn,
.btn:focus {
  background-color: $lightOrange;
}

.btn:hover {
  background-color: $darkOrange;
}

.fact-text {
  opacity: 1;
  transition: opacity 400ms linear;
}

.fact-text.no-show {
  opacity: 0;
}

.foot {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $darkBlue;
}

.foot p {
  padding-right: 40px;
}
